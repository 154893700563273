import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { ReactComponent as LogoSVG } from "../../logo.svg";

import RedDotAni from "../utils/RedDotAni";

const Wrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 12px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Logo = styled.div`
  position: relative;
  width: 190px;

  svg {
    width: 100%;
    height: 100%;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 190px;
    height: 22px;
    background: #fff;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    width: 145px;
    &:after {
      left: 100px;
      width: 30px;
      height: 17px;
    }
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 242px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    top: 13px;
    left: 134px;
    transform: scale(0.7);
  }
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  align-items: center;
`;

const GridItem = styled.div`
  text-align: right;
  align-self: end;

  &.left {
    justify-self: start;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    &.right {
      display: none;
    }
  }
`;

const Headline = styled.h2`
  font-family: "NEXTBook-Bold";
  color: #e30015;
  font-size: 1.063rem; /* 17px */
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  line-height: 1.875rem;
`;

const Subline = styled.p`
  font-size: 1.063rem; /* 17px */
  letter-spacing: 0.025rem;
  color: #e30015;
  line-height: 1.2rem;
  padding-bottom: 8px;
`;

class Header extends React.Component {
  render() {
    const { headerheadline, headersubline } = this.props.data;

    return (
      <Wrapper>
        <Grid>
          <GridItem className="left">
            <a href="/">
              <Fade bottom distance={"20px"} duration={500} delay={500}>
                <Logo>
                  <LogoSVG />
                </Logo>
              </Fade>
              <DotsWrapper>
                <RedDotAni />
              </DotsWrapper>
            </a>
          </GridItem>
          <GridItem className="right">
            <Fade bottom distance={"20px"} duration={500} delay={500}>
              <Headline>{headerheadline}</Headline>
              <Subline>{headersubline}</Subline>
            </Fade>
          </GridItem>
        </Grid>
      </Wrapper>
    );
  }
}

export default Header;
