import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Header from "./components/header";
import BackTop from "./components/backtop";
import Social from "./components/social";
import Layout from "./components/layout";
import Footer from "./components/footer";

import DotsAnimeCanvas from "./components/utils/DotsAnimeCanvas";

const theme = {
  mobilesize: "1200px",
};

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  max-width: 1440px;
  padding-left: 90px;
  padding-right: 90px;

  margin: 0 auto;
  overflow-x: hidden;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 30px;
    padding-right: 30px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
  }
`;

const AllWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
`;

const OverlayWrapper = styled.div`
  position: relative;
  top: 50vh;

  margin: 0 auto;

  max-width: 1440px;

  padding-left: 90px;
  padding-right: 90px;
  background: #ff00ee;
`;

const BackWrapper = styled.div`
  position: absolute;
  top: 50vh;
  margin-top: -152px;
  right: 32px;
  width: 30px;
`;

const LayoutWrapper = styled.div``;

const GET_BAUFABRIK_DATA = gql`
  {
    entries(
      section: ["sitelayout", "header", "social", "footer", "basicsite"]
    ) {
      title
      typeHandle

      ... on header_header_Entry {
        headerheadline
        headersubline
      }

      ... on sitelayout_sitelayout_Entry {
        components {
          id
          title
          typeHandle

          ... on hero_hero_Entry {
            heroheadline
            herosubline
            heroimage {
              url
            }
          }

          ... on herowettbewerb_herowettbewerb_Entry {
            heroheadline
            herosubline
            heroimage {
              url
            }
          }

          ... on comptext_comptext_Entry {
            headline
            copytext
          }

          ... on compimagetext_compimagetext_Entry {
            sectiontitel
            headline
            copytext
            image {
              url
            }
            buttonlabel
            buttonurl
            style
          }

          ... on compsplitcopy_compsplitcopy_Entry {
            sectiontitel
            headline
            copytextleft
            copytextright
            buttonlabel
            buttonurl
          }

          ... on contact_contact_Entry {
            sectiontitel
            headline
            telefon
            fax
            mail
            richtext
            buttonlabel
            buttonlabelshort
            eMail
          }

          ... on compintrotext_compintrotext_Entry {
            headline
            copytext
            buttonlabel
            buttonurl
          }

          ... on ablauf_ablauf_Entry {
            sectiontitel

            listitem {
              ... on listitem_item_BlockType {
                itemtitle
                itemlist
              }
            }
            image {
              url
            }
            imagemobile {
              url
            }
          }

          ... on preise_preise_Entry {
            sectiontitel
            copytextleft
            copytextright
          }

          ... on teilnahme_teilnahme_Entry {
            sectiontitel
            calltoaction
            richtext
            buttonlabel
            buttonurl
          }
        }
      }

      ... on basicsite_basicsite_Entry {
        richtext
      }

      ... on social_social_Entry {
        infotext
        socialmenu {
          ... on socialmenu_button_BlockType {
            buttonlabel
            buttonurl
          }
        }
      }

      ... on footer_footer_Entry {
        infotext
        submenu {
          ... on submenu_button_BlockType {
            buttonlabel
            buttonurl
          }
        }
      }
    }
  }
`;

function App() {
  const { data, loading, error } = useQuery(GET_BAUFABRIK_DATA);

  if (loading) return <p></p>;
  if (error) return <p>Error</p>;

  /* console.log("baufabrik data: ", data); */

  const dataHeader = data.entries.find((item) => item.typeHandle === "header");
  const dataSocial = data.entries.find((item) => item.typeHandle === "social");
  const dataFooter = data.entries.find((item) => item.typeHandle === "footer");
  const dataComponents = data.entries.filter(
    (item) => item.typeHandle === "sitelayout"
  );

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Header data={dataHeader} />

          <Switch>
            <Route exact path="/">
              <LayoutWrapper>
                <Layout data={dataComponents[0]} type="home" />
              </LayoutWrapper>
            </Route>

            <Route path="/wettbewerb">
              <LayoutWrapper>
                <Layout data={dataComponents[1]} type="bauverein" />
              </LayoutWrapper>
            </Route>
          </Switch>

          <Footer data={dataFooter} dataSocial={dataSocial} />

          <AllWrapper>
            <OverlayWrapper>
              <Social data={dataSocial} />
            </OverlayWrapper>

            <Route path="/wettbewerb">
              <BackWrapper>
                <BackTop />
              </BackWrapper>
            </Route>
          </AllWrapper>
        </Wrapper>
      </ThemeProvider>
    </Router>
  );
}

export default App;
