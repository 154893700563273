import React from "react";
import styled from "styled-components";
import anime from "animejs/lib/anime.es.js";

const Wrapper = styled.div`
  position: absolute;
  top: -2px;

  left: -10px;
  width: 1460px;
  height: 1500px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    left: 0px;
  }

  .staggering-grid {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .dot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    background: #e30015;
    transform: scale(0.1);
    transform: translate3d(0, 0, 0);
  }

  .dotwrapper {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 1px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 26px;
      height: 26px;
      content: "";
    }
  }
`;

const grid = [56, 50];

const numDots = grid[0] * grid[1];
var count = 0;

class DotsAnime extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const tar = ".staggering-grid-" + this.props.id + " .dot";
    anime.timeline({}).add({
      targets: tar,
      keyframes: [
        {
          scale: 0.1,
          duration: 0,
        },
      ],
      delay: anime.stagger(0, { grid: grid, from: 0 }),
    });

    if (this.props.force === true) {
      this.playAnimation(254);
    }
  }

  reset() {
    count = 0;
  }

  playAnimation(id) {
    const tar = ".staggering-grid-" + this.props.id + " .dot";

    if (count === 0) {
      count++;
      setTimeout(this.reset, 3500);
      //
      setTimeout(this.rocks, 500, id);
      //
    } else {
    }
  }

  rocks = (id) => {
    this.finalPlay(id);
  };

  finalPlay(id) {
    const tar = ".staggering-grid-" + this.props.id + " .dot";

    anime
      .timeline({
        easing: "easeInOutQuad",
      })
      .add({
        targets: tar,
        keyframes: [
          {
            translateX: anime.stagger("-2px", {
              grid: grid,
              from: id,
              axis: "x",
            }),
            translateY: anime.stagger("-2px", {
              grid: grid,
              from: id,
              axis: "y",
            }),
            scale: 0.25,
          },
          {
            translateX: 0,
            translateY: 0,
            scale: 0.1,
            duration: 300,
          },
        ],
        delay: anime.stagger(65, { grid: grid, from: id }),
      });
  }

  handleClick(id) {
    this.playAnimation(id);
  }

  render() {
    const arr = new Array(numDots).fill(0);
    const { id, force } = this.props;

    return (
      <Wrapper>
        <div className={`staggering-grid staggering-grid-${id}`}>
          <div className="grid">
            {arr.map((item, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  className="dotwrapper"
                  onClick={(e) => this.handleClick(index)}>
                  <span key={index} id={index} className="dot"></span>
                </div>
              );
            }, this)}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DotsAnime;
