import React from "react";
import styled from "styled-components";
import Button from "../utils/button";
import SectionTitle from "../sectiontitle";
import { useMediaQuery } from "react-responsive";

import DotsAnime from "../utils/DotsAnime";
import RedDot from "../utils/RedDot";

import VisibilitySensor from "react-visibility-sensor";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 10px;
  margin-bottom: 86px;
  padding-bottom: 100px;

  &.versionLeft {
    padding-bottom: 120px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin: 0;
    padding: 0;
    margin-bottom: 25px;
    padding-bottom: 65px;

    &.versionLeft {
      margin-bottom: 50px;
    }
  }
`;

const Grid = styled.div`
  position: relative;
  width: auto;

  height: 100%;
  padding-top: 120px;
  display: grid;

  grid-template-columns: 43% 57%;
  grid-template-rows: auto;

  align-items: start;
  justify-items: start;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const GridLeft = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  display: grid;
  grid-template-columns: 54% 46%;

  grid-template-rows: auto;

  align-items: start;
  justify-items: start;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }

  pointer-events: none;
`;

const ItemCopy = styled.div`
  padding-left: 130px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 30px;
  }

  h2 {
    padding-right: 4px;
  }
`;

const ItemCopyLeft = styled.div`
  padding-left: 110px;
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 30px;
  }
`;

const ItemImage = styled.div`
  height: 100%;
  min-height: 400px;
  padding-right: 6px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-top: 10px;
    min-height: 215px;
  }
`;

const ItemImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const Image = styled.div.attrs((props) => ({
  src: props.source || "",
}))`
  height: 100%;

  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  margin-top: 13px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const Copybox = styled.div`
  position: relative;
  pointer-events: none;
  padding-left: 85px;

  h2 {
    margin-bottom: 35px;
    margin-bottom: 28px;
    color: #e3000f;
  }

  p {
    margin-bottom: 35px;
  }

  &.style {
    padding-left: 0px;
    padding: 5px;
    margin-left: 82px;
    background-color: #fff;

    padding-top: 3px;
    margin-top: 2px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    padding-top: 50px;

    &.style {
      padding: 0px;
      margin: 0px;
    }

    h2 {
      padding-top: 20px;
    }

    &.versionLeft {
      padding-top: 0px !important;
      margin-top: 100px !important;
      padding-left: 0px !important;

      h2 {
        padding-top: 8px;
      }
    }
  }
`;

const CopyboxLeft = styled.div`
  position: relative;
  padding-top: 5px;
  padding-right: 120px;
  h2 {
    margin-bottom: 35px;
    margin-bottom: 28px;
    color: #e3000f;
  }
  p {
    margin-bottom: 35px;
  }

  &.style {
    background-color: #fff;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    padding-top: 80px;
    padding-left: 20px;

    h2 {
      padding-top: 20px;
    }
  }
`;

const RedDotWrapper = styled.div`
  position: absolute;

  top: 11px;
  left: -62px;
  &.active {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    top: 20px;
    left: 5px;
  }
`;

const RedDotWrapperMobile = styled.div`
  position: relative;
  margin-top: 30px;
  margin-left: 2px;
`;

const DotsAniWrapper = styled.div`
  position: absolute;

  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  &.versionLeft {
    top: -60px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
  }
`;

const DotsAniWrapperFix = styled.div`
  position: relative;

  top: -90px;
  left: -750px;
  right: 0px;
  bottom: 0px;
`;

class CompImageText extends React.Component {
  constructor(props) {
    super(props);
    this.state = { forcePlaying: false };

    this.handleDotClick = this.handleDotClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(isVisible) {
    if (
      isVisible === true &&
      this.props.compid === 1 &&
      this.state.forcePlaying === false
    ) {
      this.handleDotClick();
    }
  }

  handleDotClick() {
    this.setState((state) => ({
      forcePlaying: !state.forcePlaying,
    }));
  }

  render() {
    const { compid, compnum } = this.props;

    const {
      sectiontitel,
      headline,
      copytext,
      buttonlabel,
      buttonurl,
      image,
      style,
    } = this.props.data;

    const renderImage = process.env.REACT_APP_IMG_URL + image[0].url;

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1200 });
      return isMobile ? children : null;
    };

    const Desktop = ({ children }) => {
      const isMobile = useMediaQuery({ minWidth: 1200 });
      return isMobile ? children : null;
    };

    let Ani;

    if (this.state.forcePlaying) {
      Ani = <DotsAnime id={compid} force={true} />;
    } else {
      Ani = <DotsAnime id={compid} force={false} />;
    }

    return (
      <Wrapper className={" " + (compid % 2 === 0 ? "" : "versionLeft")}>
        <Mobile>
          {style === true && (
            <>
              <DotsAniWrapper
                className={" " + (compid % 2 === 0 ? "versionLeft" : "")}>
                <DotsAnime id={compid} force={false} />
              </DotsAniWrapper>
            </>
          )}
        </Mobile>

        {compid === 1 && <SectionTitle title={sectiontitel} number={compnum} />}

        {compid % 2 === 0 ? (
          <GridLeft>
            <Mobile>
              <>
                <ItemImageWrapper>
                  <ItemImage>
                    <Image source={renderImage}></Image>
                  </ItemImage>
                </ItemImageWrapper>
                <ItemCopy>
                  <Copybox className={" " + (style === true ? "style" : "")}>
                    <h2>{headline}</h2>
                    <p>{copytext}</p>
                    <Button label={buttonlabel} url={buttonurl}></Button>
                    <RedDotWrapper
                      className="active"
                      onClick={this.handleDotClick}>
                      <RedDot />
                    </RedDotWrapper>
                  </Copybox>
                </ItemCopy>
              </>
            </Mobile>
            <Desktop>
              <>
                <ItemCopyLeft>
                  <CopyboxLeft
                    className={" " + (style === true ? "style" : "")}>
                    <h2>{headline}</h2>
                    <p>{copytext}</p>
                    <Button label={buttonlabel} url={buttonurl}></Button>
                    <RedDotWrapper>
                      <RedDot />
                    </RedDotWrapper>
                  </CopyboxLeft>
                </ItemCopyLeft>
                <ItemImageWrapper>
                  <ItemImage>
                    <Image source={renderImage}></Image>
                  </ItemImage>
                </ItemImageWrapper>
              </>
            </Desktop>
          </GridLeft>
        ) : (
          <>
            <Grid>
              <ItemImageWrapper>
                <ItemImage>
                  <Image source={renderImage}></Image>
                </ItemImage>
              </ItemImageWrapper>

              <ItemCopy>
                <Desktop>
                  {style === true && (
                    <>
                      <DotsAniWrapperFix
                        className={
                          " " + (compid % 2 === 0 ? "versionLeft" : "")
                        }>
                        {Ani}
                      </DotsAniWrapperFix>
                    </>
                  )}
                </Desktop>

                {compid === 1 && (
                  <Mobile>
                    <RedDotWrapperMobile
                      className="active"
                      onClick={this.handleDotClick}>
                      <RedDot />
                    </RedDotWrapperMobile>
                  </Mobile>
                )}

                <Copybox
                  className={
                    "checker versionLeft " + (style === true ? "style" : "")
                  }>
                  <h2>{headline}</h2>
                  <p>{copytext}</p>
                  <Button label={buttonlabel} url={buttonurl}></Button>
                  <Desktop>
                    <RedDotWrapper
                      className="active"
                      onClick={this.handleDotClick}>
                      <VisibilitySensor onChange={this.onChange}>
                        {({ isVisible }) => (
                          <>
                            <RedDot />
                          </>
                        )}
                      </VisibilitySensor>
                    </RedDotWrapper>
                  </Desktop>
                </Copybox>
              </ItemCopy>
            </Grid>
          </>
        )}
      </Wrapper>
    );
  }
}

export default CompImageText;
