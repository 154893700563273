import React from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";

import Hero from "../hero";
import Contact from "../contact";
import CompIntroText from "../compintrotext";

import CompText from "../comptext";
import CompImageText from "../compimagetext";
import CompSplitCopy from "../compsplitcopy";
import CompTeilnahme from "../compteilnahme";
import CompAblauf from "../compablauf";
import CompPreise from "../comppreise";

const Wrapper = styled.div``;

class Layout extends React.Component {
  render() {
    const { data, type } = this.props;

    let compCountImageText = 0;
    let compCountSplitCopy = 0;

    return (
      <Wrapper>
        {data.components.map((comps, index) => {
          if (comps.typeHandle === "compimagetext") {
            compCountImageText++;
          }

          if (comps.typeHandle === "compsplitcopy") {
            compCountSplitCopy++;
          }

          return (
            <div key={index}>
              <Fade bottom distance={"75px"} duration={500} delay={200}>
                <>
                  {comps.typeHandle === "hero" && (
                    <Hero data={comps} type={type} />
                  )}

                  {comps.typeHandle === "herowettbewerb" && (
                    <Hero data={comps} type={type} />
                  )}

                  {comps.typeHandle === "comptext" && <CompText data={comps} />}

                  {comps.typeHandle === "compintrotext" && (
                    <CompIntroText data={comps} />
                  )}

                  {comps.typeHandle === "compsplitcopy" && (
                    <CompSplitCopy
                      data={comps}
                      compid={compCountSplitCopy}
                      compnum={2}
                    />
                  )}

                  {comps.typeHandle === "compimagetext" && (
                    <CompImageText
                      data={comps}
                      compid={compCountImageText}
                      compnum={1}
                    />
                  )}

                  {comps.typeHandle === "contact" && (
                    <Contact data={comps} compid={1} compnum={3} />
                  )}

                  {comps.typeHandle === "teilnahme" && (
                    <CompTeilnahme data={comps} compid={1} compnum={1} />
                  )}

                  {comps.typeHandle === "ablauf" && (
                    <CompAblauf data={comps} compid={1} compnum={2} />
                  )}

                  {comps.typeHandle === "preise" && (
                    <CompPreise data={comps} compid={1} compnum={3} />
                  )}
                </>
              </Fade>
            </div>
          );
        })}
      </Wrapper>
    );
  }
}

export default Layout;
