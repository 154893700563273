import React from "react";
import styled from "styled-components";
import RedDotAniBig from "../utils/RedDotAniBig";
import DotsAnimeStill from "../utils/DotsAnimeStill";

import Fade from "react-reveal/Fade";
const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  margin-bottom: 100px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin: 0px;
    height: 550px;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 142px;

  max-height: 840px;
  min-height: 520px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    bottom: 0px;
    max-height: 360px;
    min-height: 0px;
  }
`;

const Image = styled.div.attrs((props) => ({
  src: props.source || "",
}))`
  width: 100%;
  height: 100%;

  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const DotsAnimeStillWrapper = styled.div`
  position: absolute;
  left: 12px;
  top: -2px;
  right: 0px;
  bottom: 0px;
`;

const KeyVisual = styled.div`
  position: absolute;

  right: 65px;

  &.leftPos {
    right: 0;
    left: 65px;
  }

  width: 660px;
  height: 660px;
  bottom: -144px;

  user-select: none;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    transform: scale(0.6);

    margin-right: -290px;
    top: 100%;
    margin-top: -330px;

    p {
      display: none;
    }

    &.leftPos {
      margin-right: 0px;
      margin-left: -230px;

      left: 0px;
      right: auto;
    }
  }
`;

const KeyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  /*
  transition: all 0.4s ease-in-out;
  */
  transform: scale(1);

  @media (min-height: 1px) and (max-height: 768px) {
    transform: scale(0.8);
  }

  @media (min-width: 1px) and (max-width: 1200px) {
    transform: scale(0.8);
  }
`;

const Content = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;

  h1 {
    margin-bottom: 20px;
  }

  &.detail {
    p {
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 1.688rem;
    line-height: 2rem;
    line-height: 2.25rem;

    color: #fff;

    &.ext {
      padding-left: 5px;
    }
  }

  opacity: 0;
  transform: scale(1.1);
  animation-name: aniCopy;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-delay: 2.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes aniCopy {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1.01);
    }
  }
`;

class Hero extends React.Component {
  render() {
    const { heroheadline, herosubline, heroimage } = this.props.data;
    const type = this.props.type;

    const renderImage = process.env.REACT_APP_IMG_URL + heroimage[0].url;

    return (
      <Wrapper>
        <ImageWrapper>
          <Image source={renderImage}></Image>

          <KeyVisual
            className={" " + (this.props.type === "home" ? "home" : "leftPos")}>
            <KeyWrapper>
              <RedDotAniBig></RedDotAniBig>

              <Content
                className={
                  " " + (this.props.type === "home" ? "home" : "detail")
                }>
                <div>
                  {this.props.type === "home" ? (
                    <React.Fragment>
                      <h1 dangerouslySetInnerHTML={{ __html: heroheadline }} />
                      <p dangerouslySetInnerHTML={{ __html: herosubline }} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p
                        className="ext"
                        dangerouslySetInnerHTML={{ __html: herosubline }}
                      />
                      <h1 dangerouslySetInnerHTML={{ __html: heroheadline }} />
                    </React.Fragment>
                  )}
                </div>
              </Content>
            </KeyWrapper>
          </KeyVisual>
        </ImageWrapper>
      </Wrapper>
    );
  }
}

export default Hero;
